import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router";

import { ObjIsEmpty, dowloadFile } from "../../utils/helper";

import Topo from "../topo";
import Button from "../../components/button";
import FiltroContrato from "../filtroGerenciaAdm";
import Tabela from "../tabelas/admin";
import Modal from "../modal";
import ModalAdm from "../modalAdm";
import ModalAction from "../ModalActions/contrato";
import ModalVendedores from "../modalAdm/vendedores";
import ModalContratos from "../modalAdm/contratos";
import ModalCarga from "../modalAdm/cargaAdministradores";
import ModalConfirmaCarga from "../modalAdm/confirmaCarga";
import ModalConfirmaExclusaoContrato from "./Tablecontratos/modalConfirmaExclusao";
import ModalFinalizaCarga from "../modalAdm/finalizaCarga";
import ModalErroCarga from "../modalAdm/erroCarga";
import ModalVisualiza from "../modalVinculoVisualizar";
import ModalEditarContratos from "./modalEditarContrato";
import { columnsDefault } from "./columns";

import FinanciamentoContrato from "./Tablecontratos/financiamento";
import SeguroContrato from "./Tablecontratos/seguro";
import CartaoMitContrato from "./Tablecontratos/cartaoMit";

import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { Creators as AcaoAdmCreators } from "../../store/ducks/acaoAdm";
import { Creators as AprovarContratoCreators } from "../../store/ducks/aprovarContratos";
import { Creators as CargaAcaoAdmCreators } from "../../store/ducks/cargaAcaoAdm";
import { Creators as ModalActionCreators } from "../../store/ducks/modalActionContrato";

import contrato from "../../assets/img/sistema/contrato.svg";
import adm from "../../assets/img/sistema/adm.svg";
import vendedores from "../../assets/img/sistema/vendedores.svg";
import reload from "../../assets/img/sistema/reload.svg";
import { Container, Conteudo, LoadAdmActionButton } from "./styles";
import { DebounceInput } from "react-debounce-input";

import { api } from "../../services/api";
import { display } from "@mui/system";

function GerenciaAdm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [itemSelect, setItemSelect] = useState({});
  const [filtroContrato, setFiltroContrato] = useState("");
  const [loadingContratosFuncionario, setLoadingContratosFuncionario] =
    useState(false);
  const [aba, setAba] = useState("adm");
  const [searchInput, setSearchInput] = useState("");

  const [sellerManagers, setSellerManagers] = useState([]);
  const [modalExcluirContrato, setModalExcluirContrato] = useState(false);
  const [dadosExcluirContrato, setDadosExcluirContrato] = useState({});
  const arrFinanciamento = [
    "financiamento",
    "financiamento_valor",
    "financiamento_valor_fixo",
  ];

  const {
    modal_adm,
    modal_vendedores,
    modal_contratos,
    modal_carga,
    modal_confirma_carga,
    modal_finaliza_carga,
  } = useSelector((state) => state.modalAdm);

  const { modal_editar_contrato, dados: dadosContrato } = useSelector(
    (state) => state.modalEditarContrato
  );

  const { modal_action, dados } = useSelector(
    (state) => state.modalActionContrato
  );

  const { modal_erro_carga, menssagem_de_erro, data, erroData, loading } =
    useSelector((state) => {
      return state.cargaAcaoAdm;
    });

  const { modal_vinculo_visualizar } = useSelector(
    (state) => state.modalVinculo
  );

  const config = {
    title: menssagem_de_erro?.titulo ? menssagem_de_erro?.titulo : "Contratos",
    description: menssagem_de_erro?.descricao
      ? menssagem_de_erro?.descricao
      : "Algo de errado aconteceu tente novamente",
    closeModal: closeModal,
    list: erroData,
  };

  const configEditar = {
    title: "Editar Contrato",
    description: "Confirma a alteração",
    closeModal: closeModalConfirmaContratos,
    onClick: editarContrato,
  };

  const {
    location: { itemselect: url, select },
  } = history;

  if (!url) {
    history.push("/sistema");
  }

  const id = !ObjIsEmpty(itemSelect) ? itemSelect?.value : url?.value;
  const columns = React.useMemo(() => columnsDefault, []);

  const confirExclusaoContrato = {
    dados: dadosExcluirContrato,
    onClose: () => setModalExcluirContrato(false),
    type: data.acao_type,
    contrato_id: id,
  };

  function closeModal() {
    dispatch(CargaAcaoAdmCreators.modal_erro_carga());
  }

  function editarContrato() {
    dispatch(
      AprovarContratoCreators.request_contrato_adm({
        acao_type: data.acao_type,
        user: dados,
        contrato: dadosContrato,
        id,
      })
    );
  }

  function closeModalConfirmaContratos() {
    dispatch(ModalActionCreators.close());
  }

  async function exportarPontos(acao_id) {
    setLoadingContratosFuncionario(true);

    await dowloadFile(`acao/${acao_id}/export`);

    setLoadingContratosFuncionario(false);
  }

  useEffect(() => {
    if (url) {
      dispatch(CargaAcaoAdmCreators.request(id));
    }
  }, [url, dispatch, id]);

  useEffect(() => {
    setFiltroContrato("");
  }, [data]);

  const handleSearchSellerManager = (e) => {
    let value = "";
    if(e.target){
      value = e.target.value;
    }else{
      value = e;
    }
    setSearchInput(value);
    if (value.length >= 3) {
      api
        .get(`funcionario/get-funcionario-by-name-cpf/${value}`)
        .then((response) => {
          const data = response.data.resource;
          setSellerManagers(data);
        });
    }else{
      setSellerManagers([]);
    }
  }

  const handleRemoveBind = (documento) => {
    api
      .post(`funcionario/unlock-economic-group/${documento}`)
      .then((response) => {
        console.log(response.data)
        if (response.data.resource) {
          handleSearchSellerManager(searchInput);
        }
      });
  }

  return (
    <>
      <Container>
        <Conteudo>
          <Topo />
          <FiltroContrato
            select={select}
            setItemSelect={setItemSelect}
            placeholder={url?.label}
            tipo={data.acao_type}
          />
          <div className="conteudo">
            <div className="abas">
              <span
                className={`${aba === "adm" ? "item active" : "item"}`}
                onClick={() => {
                  dispatch(
                    AcaoAdmCreators.acao_type({
                      type: "user",
                      acao_id: id,
                    })
                  );
                  setAba("adm");
                }}
              >
                <img src={adm} alt="" /> Administradores
              </span>
              <span
                className={`${aba === "vendedores" ? "item active" : "item"}`}
                onClick={() => {
                  dispatch(
                    AcaoAdmCreators.acao_type({
                      type: "funcionario",
                      acao_id: id,
                    })
                  );
                  setAba("vendedores");
                }}
              >
                <img src={vendedores} alt="" /> Vendedores e Gerentes
              </span>
              <span
                className={`${aba === "contratos" ? "item active" : "item"}`}
                onClick={() => {
                  dispatch(
                    AcaoAdmCreators.acao_type({
                      type: "contrato",
                      acao_id: id,
                    })
                  );
                  setAba("contratos");
                }}
              >
                <img src={contrato} alt="" /> Contratos
              </span>
            </div>

            {aba === "adm" && (
              <>
                <div className="topo">
                  <span>Total de ADM - {data.admList.length}</span>
                  <div className="funcionario">
                    <LoadAdmActionButton
                      type="button"
                      isLoading={loading}
                      onClick={() => dispatch(CargaAcaoAdmCreators.request(id))}
                    >
                      <img src={reload} alt="" />
                    </LoadAdmActionButton>

                    <Button
                      active={true}
                      title="Cadastrar ADM"
                      onClick={() =>
                        dispatch(ModalCreators.modal_carga("user"))
                      }
                    />
                  </div>
                </div>

                <Tabela
                  columns={columns}
                  data={data.admList ? data.admList : []}
                  tipo="adm"
                />
              </>
            )}

            {aba === "vendedores" && (
              <>
                <div className="topo">
                  <span>
                    Total de Vendedores e Gerentes - {data.vendedorList.length}
                  </span>

                  <div className="funcionario">
                    <LoadAdmActionButton
                      type="button"
                      isLoading={loading}
                      onClick={() => dispatch(CargaAcaoAdmCreators.request(id))}
                    >
                      <img src={reload} alt="" />
                    </LoadAdmActionButton>
                    {/* <Button
                      active={true}
                      title="Funcionarios"
                      onClick={() => {
                        exportarPontos(data.vendedorList[0].acao_id);
                      }}
                      loading={loadingContratosFuncionario}
                    /> */}

                    <Button
                      active={true}
                      title="Cadastrar Vendedores e Gerentes"
                      onClick={() =>
                        dispatch(ModalCreators.modal_carga("funcionario"))
                      }
                    />
                  </div>
                </div>

                <Tabela
                  columns={columns}
                  data={data.vendedorList ? data.vendedorList : []}
                  tipo="vendedores"
                />

                <div className="container-editar-contrato">
                  <div className="title" style={{ marginBottom: "10px" }}>
                    <h3>
                      Preencha o campo abaixo para localizar um vendedor ou
                      gerente
                    </h3>
                  </div>

                  <div className="container-busca" style={{ marginBottom: "20px" }}>
                    <DebounceInput
                      placeholder="Nome ou CPF"
                      onChange={(e) => handleSearchSellerManager(e)}
                      style={{ width: "550px", maxWidth: "100%" }}
                      value={searchInput}
                    />
                  </div>

                  {(sellerManagers.length > 0) && (<div>
                    <table style={{ width: "850px", maxWidth: "100%", borderCollapse: "collapse" }}>
                      <thead>
                        <tr>
                          {/* nome */}
                          <th style={{ fontSize: "18px", fontWeight: "600", textAlign: "left", padding: "0 0 10px 10px" }}>Nome</th>
                          <th style={{ fontSize: "18px", fontWeight: "600", textAlign: "left", padding: "0 0 10px 0" }}>Documento</th>
                          <th style={{ fontSize: "18px", fontWeight: "600", textAlign: "left", padding: "0 0 10px 0" }}>F5/Grupo</th>
                          <th style={{ fontSize: "18px", fontWeight: "600", textAlign: "left", padding: "0 0 10px 0" }}>Cargo</th>
                          <th style={{ fontSize: "18px", fontWeight: "600", width: "200px", padding: "0 0 10px 0" }}>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sellerManagers.map((sellerManager, index) => (
                          <tr style={{ background: index % 2 === 0 ? "rgba(0,0,0,.04)" : "rgba(0,0,0,.01)" }} key={index}>
                            <td style={{ padding: "10px 0 10px 10px", fontSize: "15px", color: "rgba(0,0,0,.7)" }}>{sellerManager.nome}</td>
                            <td style={{ fontSize: "14px", color: "rgba(0,0,0,.7)" }}>{sellerManager.documento}</td>
                            <td style={{ fontSize: "14px", color: "rgba(0,0,0,.7)" }}>{sellerManager.economic_group_locked ? sellerManager.grupo_economico : "-"}</td>
                            <td style={{ fontSize: "14px", color: "rgba(0,0,0,.7)" }}>{sellerManager.economic_group_locked ? sellerManager.tipo : "-"}</td>
                            <td style={{ textAlign: "center", color: "#930035", padding: "7px 0", cursor: "pointer", fontSize: "15px" }}>
                              <div
                                style={!sellerManager.economic_group_locked ? {display: "none"} : {}}
                                onClick={() => {
                                  handleRemoveBind(sellerManager.documento);
                                }}
                              >
                                <span>Remover vínculos</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>)}
                </div>
              </>
            )}

            {aba === "contratos" && (
              <>
                <div className="topo">
                  <span>Total de Contratos - {data.contratosList.length}</span>
                  <div className="funcionario">
                    <LoadAdmActionButton
                      type="button"
                      isLoading={loading}
                      onClick={() => dispatch(CargaAcaoAdmCreators.request(id))}
                    >
                      <img src={reload} alt="" />
                    </LoadAdmActionButton>
                    <Button
                      active={true}
                      title="Cadastrar Contrato"
                      onClick={() => dispatch(ModalCreators.modal_carga())}
                    />
                  </div>
                </div>

                <Tabela
                  columns={columns}
                  data={data.contratosList ? data.contratosList : []}
                  tipo="contratos"
                />

                <div className="container-editar-contrato">
                  <div className="title">
                    <h3>
                      Preencha os campos abaixo para localizar um contrato
                    </h3>
                  </div>

                  <div className="container-busca">
                    <DebounceInput
                      placeholder="Nª do contrato"
                      type="number"
                      debounceTimeout={1000}
                      value={filtroContrato}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFiltroContrato(e.target.value);
                      }}
                    />
                  </div>

                  {filtroContrato !== "" &&
                  arrFinanciamento.includes(data.acao_type) ? (
                    <FinanciamentoContrato
                      filtro={filtroContrato}
                      setModalExcluirContrato={setModalExcluirContrato}
                      setDadosExcluirContrato={setDadosExcluirContrato}
                    />
                  ) : null}

                  {filtroContrato !== "" && data.acao_type === "seguro" ? (
                    <SeguroContrato
                      filtro={filtroContrato}
                      setModalExcluirContrato={setModalExcluirContrato}
                      setDadosExcluirContrato={setDadosExcluirContrato}
                    />
                  ) : null}

                  {filtroContrato !== "" && data.acao_type === "cartao_mit" ? (
                    <CartaoMitContrato
                      filtro={filtroContrato}
                      setModalExcluirContrato={setModalExcluirContrato}
                      setDadosExcluirContrato={setDadosExcluirContrato}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </Conteudo>

        <Modal component={ModalAdm} active={modal_adm} />
        <Modal component={ModalVendedores} active={modal_vendedores} />
        <Modal component={ModalContratos} active={modal_contratos} />

        <Modal component={ModalCarga} active={modal_carga} />

        <Modal component={ModalConfirmaCarga} active={modal_confirma_carga} />

        <Modal
          component={ModalConfirmaExclusaoContrato}
          active={modalExcluirContrato}
          rest={confirExclusaoContrato}
        />

        <Modal component={ModalFinalizaCarga} active={modal_finaliza_carga} />
        <Modal
          component={ModalErroCarga}
          active={modal_erro_carga}
          rest={config}
        />

        <Modal
          component={ModalEditarContratos}
          active={modal_editar_contrato}
        />

        <Modal
          component={ModalAction}
          active={modal_action}
          rest={configEditar}
        />

        <Modal component={ModalVisualiza} active={modal_vinculo_visualizar} />
      </Container>
    </>
  );
}

export default GerenciaAdm;
